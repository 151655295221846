import React from "react"
import { Helmet } from "react-helmet"

const PrivacyPage = () => (
  <>
    <Helmet>
      <title>Privacy Policy | Fraser Community Child Care Centre & Out of School Care</title>
      <link rel="canonical" href="https://frasercommunitychildcarecenter.com/404" />
    </Helmet>
    <div className="grid">
      <section className="grid__item section">

        <div className="text__container">
          <h1 className="heading heading--section heading--no-skew">Privacy Policy</h1>
          <p className="text--pre">This Privacy Policy describes how your personal information is collected, used, and shared when you visit frasercommunitychildcarecenter.com (the “Site”).</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">PERSONAL INFORMATION WE COLLECT</h2>
          <p className="text--pre">When you submit information through the contact form, we collect the following information: name, email.
          We refer to this information as "Personal Information".</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
          <p className="text--pre">We use the Personal Information to communicate with and notify you.</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">SHARING YOUR PERSONAL INFORMATION</h2>
          <p className="text--pre">We may share your Personal Information with third parties to help us use your Personal Information, as described above. 

          Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">DATA RETENTION</h2>
          <p className="text--pre">When you send info through the contact form, we will maintain your Information for our records unless and until you ask us to delete this information.</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">CHANGES</h2>
          <p className="text--pre">We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
        </div>

        <div className="text__container">
          <h2 className="heading heading--subsection">CONTACT US</h2>
          <p className="text--pre">For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us through the form on the Contact page.</p>
        </div>
        
      </section>
    </div>
  </>
)

export default PrivacyPage
